import React from "react"

import Page from "../../components/Page"
import BannerParallax from "../../components/BannerParallax"

import imgBackground from "../../images/banner/banner-9-online-program.jpg"
import OnlineProgram from "./OnlineProgram"

export default () => {
    const pageTitle = "Online Program"
    return (
        <Page title={pageTitle} className="page-online-program">
            <BannerParallax title={pageTitle} background={imgBackground} />
            <OnlineProgram />
        </Page>
    )
}
