import React from "react";
import imgOnlineProgram01 from "../../images/online-program/online-program-01.jpg";
import imgOnlineProgram02 from "../../images/online-program/online-program-02.jpg";
import imgOnlineProgramMusicWorldWide from "../../images/online-program/online-program-music-world-wide.png";
import imgOnlineProgramFastTrack from "../../images/online-program/online-program-fast-track.png"

import { Card, Image } from "react-bootstrap";
import { Link } from "gatsby";

export default () => {
    return (
        <section className="section_padding">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12">
                        <div className="section_title text-center">
                            <h2>Online Program</h2>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-6 mb-2">
                        <Link to="/online-program/music-world-wide">
                            <Card className="mb-4 shadow-sm hoverable">
                                <img className="card-img-top" src={imgOnlineProgram01} alt="MusicWorldWide" />
                                <Card.Body>
                                    <Image src={imgOnlineProgramMusicWorldWide} fluid className="px-4 py-2" />
                                </Card.Body>
                            </Card>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Card className="mb-4 shadow-sm hoverable">
                            <img className="card-img-top" src={imgOnlineProgram02} alt="Fast Track" />
                            <Card.Body className="text-center">
                                <Image src={imgOnlineProgramFastTrack} fluid className="px-4 py-1" />

                                <div class="d-flex justify-content-around">
                                    <p className="m-0 font-weight-bold">
                                        <Link to="/online-program/fast-track?lang=en">
                                            Produce &amp; Launch Your Single <span role="img" aria-label="en">🇬🇧</span>
                                        </Link>
                                    </p>
                                    <p className="mb-2">|</p>
                                    <p className="m-0 font-weight-bold">
                                        <Link to="/online-program/fast-track?lang=id">
                                            Produksi &amp; Rilis Lagumu di Spotify <span role="img" aria-label="id">🇮🇩</span>
                                        </Link>
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};
